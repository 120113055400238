@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
.App {
  text-align: center;
}



/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
	font-family: 'SEGOEUIL';
	src: url('Components/Fonts/SEGOEUIL.eot');
	src: local('SEGOEUIL'), url('Components/Fonts/SEGOEUIL.woff') format('woff'), url('Components/Fonts/SEGOEUIL.ttf') format('truetype');
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_1409">Text with this font applied</p> */
.fontsforweb_fontid_1409 {
	font-family: 'SEGOEUIL' !important;
}

/* Font downloaded from FontsForWeb.com */










@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('Components/Fonts/MYRIADPRO-REGULAR.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('Components/Fonts/MYRIADPRO-COND.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('Components/Fonts/MYRIADPRO-CONDIT.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('Components/Fonts/MyriadPro-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('Components/Fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('Components/Fonts/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('Components/Fonts/MYRIADPRO-BOLDCOND.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('Components/Fonts/MYRIADPRO-BOLD.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('Components/Fonts/MYRIADPRO-BOLDIT.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('Components/Fonts/MYRIADPRO-BOLDCONDIT.woff') format('woff');
  }






















  









body {
  font-size: 14px;
  color: #716e6e;
  /* font-family: "Roboto",sans-serif; */
  font-weight: 400;
  line-height: 1.5715;
  background: #ffff;
  background-repeat: repeat;
  background-position: top;
  background-attachment: fixed!important;
  background-size: cover;
  position: relative;
  min-height: 100%;
  font-family: 'Myriad Pro Regular' !important;
 
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.logo img {
  height: 70px;
  width: 470px;
  margin: 0 auto;
  display: inline-block;
}
.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
  display: block;
  max-width: 100%;
  height: auto;
}
.logo {
  padding: 20px 0;
  text-align: center;
}
.outer-wrapper {
  padding: 5% 0px;
  background: #004a7e;
}
.info-sec {
  background: rgba(255, 255, 255, 0.8);
  padding: 25px;
  min-height: 214px;
  border-top: 5px solid rgb(14, 44, 142);
}
.login-head {
  border-bottom: 2px solid #080808;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
h1 {
  font-family: 'Roboto', sans-serif !important;
  font-size: 26px;
  font-weight: 400;
  color: #000000;
}
.button:hover, .login-inner input[type=submit]:hover {
  background-color: #0b4e85;
  color: #fff;
  border: 1px solid #0b4e85!important;
}
.button, .login-inner input[type=submit] {
  background-color: #867a69;
  color: #fff;
  font-family: 'Roboto', sans-serif !important;
  border: 1px solid #867a69!important;
  transition: ease-in-out .3s;
  -webkit-transition: ease-in-out .3s;
  -moz-transition: ease-in-out .3s;
  padding-left: 0;
  font-style: normal!important;
  font-size: 14px!important;
}
.logo-outer {
  margin-bottom: 50px;
  border-bottom: 5px solid #0b4e85;
}
.navbar.navbar-default.nav-new {
  border: 1px solid #0b4e85;
  margin-top: 12px;
  height: 35px !important;
  min-height: auto;
  background: #0b4e85;
}
.dropdown-submenu {
  position: relative;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.main-section-top h1 {
  font-size: 60px;
  text-align: center;
  text-shadow: 3px 3px 4px #333;
  font-weight: bold;
  margin-bottom: 0px;
  color: #fff;
}
.main-section-top {
  background: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
  text-align: center;
  padding: 11px 0px 0px;
  border: 1px solid #919094;
}
.button, .login-inner input {
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
  outline: none;
}
h2, h3, h4 {
  font-weight: 700 !important;
  font-size: 18px !important;
}
.login-inner input {
  padding-left: 10px;
  border: 1px solid #c9cac8;
  transition: ease-in-out .3s;
  -webkit-transition: ease-in-out .3s;
  -moz-transition: ease-in-out .3s;
  font-style: italic;
  font-size: 13px;
  font-family: 'Roboto', sans-serif !important;
}
.forgot {
  width: 100%;
  background: #867a69;
  color: #fff;
  text-align: center;
  padding: 10px;
}
a {
  text-decoration: none !important;
}
footer.login-footer {
  text-align: center;
  /* position: absolute; */
  left: 0px;
  right: 0px;
  bottom: 0px;
  position:absolute;
}
.login-footer {
  background: #867a69;
  color: #fff;
  /* padding: 26px 0 30px; */
}
footer.login-footer p {
  margin-bottom: 0px;
  
}
.dropdown-menu {
 border: 0px solid;
}
.col-md-6.col-sm-5 p {
  text-align: left;
}
.col-md-6.col-sm-7 {
  text-align: right;
}
footer.login-footer.fst {
  background: #575858;
}
footer.login-footer.fst {
  margin-bottom: 15px;
}
.dropdown-submenu-container .dropdown-menu {
  margin-left: 0px !important;
  margin-right: auto;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #0b4e85;
}
a.dropdown-item {
  font-size: 14px;
}
.login-head~p {
  line-height: 1.5;
}
.container.footer {
  max-width: 100%;
  padding: 11px;
}
a.primary-btn:hover{
  color: #fff;
  background: #867a69;
  border: 1px solid #867a69;
}
.view-reward:hover {
  color: blue;
}
.view-reward a {
  color: #ffffff;
}

.info-sec p {
  color: #0b4e85;
}
/* label {
  display: inline-block;
  max-width: 100%;

  font-weight: 700;
} */
.info-sec img {
  padding: 0px 34px;
}
.info-sec {
  background: rgba(255, 255, 255, 0.8);
  padding: 25px;
  min-height: 214px;
  border-top: 5px solid #867a69;
}
.modal {
  text-align: center;
  padding: 0!important;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-header {
  background: #867a69;
}
div#myModal h4 {
  color: #fff;
  font-size: 17px;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.login-wrapper {
  height: auto;
}
.login-inner {
  border-top: 5px solid #867a69;
  padding: 28px 30px 38px;
  background: rgba(255, 255, 255, 0.8);
}

.login-inner input[type=email], .login-inner input[type=text] {
  background: url(https://gulfeaglerewardsloyalty.com/images/email.png) no-repeat center right #fff;
  padding-right: 50px;

}
.login-inner input[type=password] {
  background: url(https://gulfeaglerewardsloyalty.com/images/key.png) no-repeat center right #fff;
  padding-right: 50px;
}
.button, .login-inner input {
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
  outline: none;
}
.container-fluid {
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;
}
h1.login-head {
  font-size: 26px;
  font-size: 26px;
  font-weight: 400;
  color: #0b4e85;
}
.login-head {
  border-bottom: 2px solid #0b4e85;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
@media (min-width: 1200px)
{
.container {
    width: 1170px !important;
}
}

nav.navbar.navbar-expand-lg.navbar-light.bg {
  border: 1px solid #0b4e85;
  margin-top: 12px;
  height: 35px !important;
  min-height: auto;
  background: #0b4e85;
  /* background: blue; */
}


.navbar-light .navbar-nav .nav-link {
    color: #4b4c4c !important;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  background: #ffffff !important;
  color: rgb(255 255 255);
}
div#basic-navbar-nav {
  background: #ffffff !important;
}
/* .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgb(0 74 126) !important;
  
} */
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
}
.navbar {
  padding-top: 0rem;
  padding-bottom: 0rem !important;
}
div#basic-navbar-nav {
  padding-left: 15px;
  padding-right: 15px;
}
a.nav-link {
  position: relative;
  padding: 7px 15px !important;
}
.dropdown-menu
{
  background-color: #867a69  !important;
  border-radius: 0px !important;
  
}
.dropdown-item{
  color: #fff !important ;
}
.dropdown-menu[data-bs-popper] {
  margin-top: 0rem !important;
}
.dropdown-item:hover {
  color: #fff !important;
  background-color: #564f43 !important
}

.main-section {
  background: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
  text-align: center;
  padding: 11px;
  border: 1px solid #919094;
}
.login.section {
  padding: 20px;
}
.login.section h2 {
  color: #fff;
  margin-top: 25px;
  font-size: 34px !important;
  font-weight: 500 !important;
  letter-spacing: 15px;
}
.login.section p {
  font-size: 20px;
  color: #fff;
  line-height: 28px;
}
.login.section img {
  margin: 30px auto;
  width: 70%;
}

.boder-outer {
  border-width: 0px 0px 0px 1px;
  padding-left: 30px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from(#919296), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(#919296, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image: -moz-linear-gradient(#919296, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image: -o-linear-gradient(#919296, rgba(0, 0, 0, 0)) 1 100%;
  border-image: linear-gradient(to bottom, #0b4e85 , rgba(0, 0, 0, 0)) 1 100%;
}
.points {
  background: #867a69;
  color: #fff;
  padding: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

a.primary-btn {
  background: #0b4e85;
  border: 1px solid #0b4e85;
  color: #fff;
  font-weight: 600;
  display: block;
  text-decoration: none !important;
  padding: 5px;
  margin-bottom: 10px;
}

svg.gift {
  font-size: 76px;
  padding: 16px;
  width: 110px;
  text-decoration: none !important;
  margin: 0 auto;
  display: block;
  height: 110px;
  margin-bottom: 10px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50%;
}


.nav-bg div#basic-navbar-nav{
  width: auto;
  height: 29px;
  background: url(https://otb-demo.com/images/nav_bg.gif) !important;
}

/* .nav-bg .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link{
  color: black !important;

} */
.cat-div {
  width: auto;
  padding-top: 25px;
  padding-bottom: 3px;
  background: #0b4e85;
  padding-left: 10px;
}
.page-title {
  width: 960px;
  height: 93px;
  /* background-color: #FFF; */
  color: #FFF;
  margin: 0 auto;
}
.cat-div {
  color: #fff;
}
form.otbdemo{
  font-size: 10pt;
  margin-bottom: 20px;
  width: auto;
}
.btn_1:hover {
  background-color: #0b4e85;
  border: 1px solid #0b4e85;
  border-right: none;
}
.green-bar {
  background: #867a69;
  width: auto;
  padding-bottom: 5px;
  padding-top: 5px;
}
.pointstext {
   color:#fff;
  font-size: 12px;
  float: left;
  margin-left: 5px;
  margin-top: 0px;
}
.shopingcarttext {
  color: #ffff;
}
.nav-bg {
  height: 93px !important;
  width: 960px !important;
  margin: 0 auto;
}
.inner-page-title {
  width: auto;
  margin: 0 auto;
}
.inner-page-title a.nav-link {
  font-size: 12px;
}
.inner-page-title a.nav-link
{
  padding: 3px 20px !important;
}

.contents {
  width: 960px;
  height: auto;
  margin: 0 auto;
  background: url('https://otb-demo.com/assets/images/bg-white.png')  #949494;
  padding-top: 20px;
  margin-top: 39px;
}
.right-col {
  width: 940px;
  z-index: 999;
  padding: 10px;
}
.inner-page-title a.nav-link
{
  padding: 8px 5px 5px 15px;
  display: block;
  display: block;
  position: relative;
  float: left;
  margin-top: 0px;
  width: 141px;
 

}
a.home.nav-link {
  width: 90px;
}

/* .inner-page-title .nav-ul li {
  display: block;
  position: relative;
  float: left;
  margin-top: 0px;
  width: 141px;
} */
strong {
  font-weight: 700;
}

/* .btn_1 {
  padding-left: 15px;
  padding-right: 15px;
  height: 27px;
  color: #FFF;
  background-color: #867a69;
  border: none;
  cursor: pointer;
  transition: ease-in-out 0.7s;
  border: 1px #867a69 solid;
  border-right: none;
} */

.inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover
{
  background: #ffffff !important;
}
.go-back-div {
  float: right;
}
.cat-heding {
  width: auto;
  border-bottom: 2px solid #0b4e85;
  color: #0b4e85;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.green-div-bg {
  width: 958px;
  height: 70px;
  border: 1px solid #FFF;
  background: #0b4e85;
  margin: 0 auto;
}
/* .btn {
  padding-left: 12px;
  padding-right: 12px;
  height: 34px;
  width: 237px;
  border: 0;
  margin-left: 1px;
  cursor: pointer;
  color: #FFF;
  background-color: #0b4e85;
  transition: ease-in-out 0.7s;
  font-size: 13px;
  border-radius: 5px;
  letter-spacing: 2px;
  outline: none;
}
.btn:hover {
  background-color: #867a69;
} */
.notes {
  list-style: initial;
  color: #F53232;
}
.cate-TextBox {
  margin-bottom: 5px !important;
  width: 225px;
  height: 25px;
  border: 1px solid #0053a0;
  padding: 5px;
  border-radius: 5px;
  outline: none;
}

.pagesnotes{
  margin: 20px;
  display: inline-block;
  width: 890px;
}
a.viewall {
  font-size: 12px;
  color: #195a92;
  text-decoration: underline;
}
.top {
  color: white;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: center;
  padding-top: 15px;
}
.top .topaay{ 
  color: white;
  font-size: 16px;
}

.imgstyle{
  width: 16px;
  height: 16px;
}
.addnewemail{
  font-size: 12px !important;
    color: #195a92 !important;
}

select {
  font-family: arial;
  font-size: 16pt;
  width: 100px;
}
.tinytable th {
  background-color: #0b4e85 !important;
  color: #F7FBFF;
  border-right: 1px solid #fff;
}
.tinytable h3 {
  text-align: center !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.img-act {
  height: 12px;
  width: 12px;
  padding: 0 3px 0 5px;
  vertical-align: text-top;
}
.cat-heding {
  width: auto;
  border-bottom: 2px solid #0b4e85;
  color: #0b4e85;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.cat-heding h2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #2f526e;
  float: left;
  margin: 0px;
}
#banner {
  margin: 60px 20px 20px 20px;
  padding: 0;
  text-align: left;
}
#banner {
  background-repeat: no-repeat;
  background-position: center center;
}
.ban-table {
  clear: both;
  margin: 0 auto;
}
.preview_bannner {
  width: 918px;
  height: 495px;
  border: 1px solid #0053a0;
  float: left;
  margin-bottom: 10px;
  background-color: #E5E5E5;
}
strong.Upload {
  color: #020202;
}
hr.borderline {
  border: 1px solid #0053a0 !important;
}
.me-auto.colornav.navbar-nav .navbar-light .navbar-nav .nav-link{
  color: white !important;
}







.nav-bg.sub .navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0) !important;
}
.nav-bg.sub .navbar-light .navbar-nav .nav-link:hover{
  color: white !important;
}
/* .me-auto.navbar-nav .inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover
{
  background: #564f43 !important;
} */

/* 
.inner-page-title .me-auto.navbar-nav .nav-bg .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link
{
  color: rgb(0, 0, 0) !important;
} */
hr {
  border: 1px solid #0053a0 !important;
}
input#query {
  margin: 10px !important;
}



.forgot a {
  color: white;
  text-decoration: none;
}











img.img {
  width: 100%;
}
img.img2 {
  width: 100%;
  /* float: right; */
  /* width: 40%; */
}
.col-md-4 {
  background: white;
}
.box {
  background: #4caf50;
}
/* .rect {
  padding-top: 30px;
  padding-bottom: 30px;
} */
.boxes {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}
.headerLogin {
  background: cornsilk;
}
.\36 {
  background: #fff;
}
.\32 {
  background: aqua;
}




img.logo {
  padding: 0px;
  width: 260px;
  float: left;
}

.col-md-6.logodiv {
  background: #ffff;
}
.catalog {
  text-align: center;
  padding-top: 25px;
}
.col-md-2.catalogdiv {
  background: #ffff;
  border-right: 2px solid #d4d4d4;
  height: auto;
}
span.email {
  font-size: 16px;
  color: #004a7e;

  padding-right: 5px;
}
span.phone {
  font-size: 16px;
  margin-left: 5px;
  border-left: 2px solid #bfbdbd;
  color: #004a7e;
  padding-left: 10px;
}
h2.question {
  font-weight: 400 !important;
  padding: 0px;
  margin: 0px;
  line-height: 1;
  font-size: 36px !important;
}
small.address p {
  font-style: italic;
  color: #747474;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}
img.dimensions {
  width: 60px;
    margin-right: 10px;

}

.col-md-4 {
  padding: 10px;
}
.col-md-10 {
  padding: 0px;
}

.navbar-light .navbar-brand {
  color: rgb(255 254 254 / 90%);
}

a.catalog.nav-link {
  font-size: 16px;
  color: #004a7d !important;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.handph {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link {
    color: #4b4c4c !important;
}
div#basic-navbar-nav {
  background: #f8f9fa;
}
.email span{
  color: #004a7e;
  margin: 0px !important;
  padding: 0px !important;
}

 span .email{
  color: #004a7e;;
}

.phone span {
  margin: 0px !important;
  padding: 0px !important;
}
 p {
  margin: 0px !important;
  padding: 0px !important;
}


a.nav-link:last-child {
  padding-right: 0px !important;
}
.nav-link:last-child{
  position: relative;
}
.nav-link:last-child::before {
  content: '';
  position: absolute;
  top: 19px;
  width: 0px;
  border-left: 1px solid #bfbdbd;
  z-index: 11;
  height: 49px;
  left: 0px;
}


img.centralimages {
  /* height: 46vh; */
  width: 60%;
}
.bkg {
  background: #fff;
  width: 85%;
  position: relative;
   padding-top: 100px; 
  padding-bottom: 80px;
  min-height: calc(100vh - 175px);
  display: flex;
  align-items: center;
}

.foram {
  width: 40%;
  float: right;

}



input[type=text], select {
  font-size: 16px;
  width: 100%;
  padding: 9px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 1px;
  box-sizing: border-box;
  height: 55px;
}

input[type=submit] {
  height: 55px;
  font-size: 15px !important;

  width: 24%;
  background-color: #ffae00;
  color: #454545;
  padding: 7px 9px;
  margin: 0px;
  border: none;
  border-radius: 0px;
  margin-right: 0px !important;


}
.input-group.checking {
  /* position: relative; */
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
a.btn.btn-link.forgot {
  font-size: 12px;
  padding: 0px;
}
.forgot {

  background: #ffffff;
  color: #394c5a;
  text-align: center;
  padding: 10px;
}
a.btn.btn-link.forgot {
  width: auto;
  height: auto;
  display: inherit;
}

input[type=submit]:hover {
  background-color: #004a7e;
  color: #fff;
}

.foram {
  margin: auto;
  width: 40%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}




.info {
display: flex;
align-content: center;
justify-content: space-between;
align-items: center;
}
nav.navbar.navbar-expand-lg.navbar-light {
  position: absolute; 
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  padding: 20px 50px 20px;
}


img.googleplay {
  width: 10%;
  /* width: 208px; */
  padding: 5px;
}
.centraltext {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px;
}
.centraltext.footerlogin {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  bottom: 0;
  padding-bottom: 20px;
}
/* a.appstore {
  margin-right: 10px;
} */
a.googleplay {
  margin-left: 5px;
}
a.appstore {
  margin-right: 5px;
}
.input-group.checking {
  margin-top: 15px;
}
label.remember {
  font-size: 12px;
}
.addresssection {
  line-height: 1.2;
}
.footerbotm {
  margin-top: 30px;
  font-size: 14px;
  color: #ffff;
  text-align: center;
  height: 44px;
   /* position: absolute;  */
 
  /* padding-bottom: 10px; */

}
/* a.distr.nav-link {
  pointer-events: none;
  cursor: default;
} */
input#password {
  width: 100%;
  padding: 9px 20px;
  margin: 5px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 1px;
  box-sizing: border-box;
  height: 55px;
}
svg.svgicon {
  color: #1c252cad;
  margin-top: 0px;
  position: absolute;
  width: 8px;
  margin-left: 125px;
}
input#vehicle1 {
  vertical-align: baseline;
}
.container.fpage {
  padding-top: 30px;
  float: left;
  padding-bottom: 30px;
}

.underconst{
text-align: center;
}
a.logofpage.navbar-brand {
  color: #2d2d2d !important;
  font-weight: 400 !important;
  font-size: 16px  !important;
}
a.fpagenav.nav-link {
  font-size: 16px;
}











.cart{
  text-align: right;
}
ul.pointsdetail {
  margin-left: auto;
  text-align: right;
  padding-left: 0px;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
ul.pointsdetail li{
  font-weight: 400;
  padding-right: 20px;

  padding-bottom: 5px;
  text-align: left;
  padding-top: 15px;
  font-size: 30px !important;
  color: #4b4c4c;
}

ul.pointsdetail li:last-child{
  padding-right: 0px;
}
span.pointsav {
  font-size: 18px;
  font-weight: 400;
  display: block;
}
.container-fluid.fpage {
  padding-bottom: 0px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
span.pointsav {
  line-height: 1;
}
span.logout {
  border-left: 2px solid #716e6e;
  padding-left: 5px;
}
span.shop {
  padding-right: 10px;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-dark {
  padding-top: 0px;
  /* background: darkblue; */
}

nav.navfpage.navbar.navbar-expand-lg.navbar-light {
  background: #e8e8e8;
}
nav.navfpage.navbar.navbar-expand-lg.navbar-light {
  padding-top: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
}
a.logofpage.navbar-brand {
  color: #4b4c4c !important;
  font-weight: 500;
}
.col-md-5.fpage {
  padding-left: 0px;
  padding-right: 0px;
}
.col-md-7.fpage {
  padding-left: 0px;
  padding-right: 0px;
}
a.fpagenav.nav-link::before {
  content: none;
}
/* a.fpagenav.nav-link {
  border-left: 1px solid #bbbbbb;
} */


/* a.fpagenav.nav-link::before {
    content: '';

    top: 10px;
    width: 0px;
    border-left: 1px solid #bfbdbd;
    z-index: 11;
    height: 52px;
    left: 0px;
} */
a.fpagenav.nav-link {
  background: none !important;
}
/* img.logo.fpage {
  position: absolute;
  top: 10px;
  bottom: 0;
  
} */
.col-md-5.fpage {
  position: relative;
}
a.fpagenav.nav-link {
    font-weight: 400 !important;
}
a.fpagenav.nav-link:hover {
  background-color: #867a69;
color: #fff;
opacity: 1;
}

.inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .fpagenav.nav-link:hover {
  background: #716e6e !important;
  color: #fff !important;
}
a.fpagenav.nav-link:last-child {
  padding: 7px 14px !important;
}


.inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .fpagenav.nav-link:focus {
  background: #716e6e !important;
  color: #fff !important;
}
.centraldata.fpage {
  text-align: center;
  line-height: 2.1;
  font-size: 18px;
  padding-top: 40px;
  max-width: 973px;
  margin: 0 auto;
}
p.fisrtline {
  font-weight: 400 !important;
  font-size:36px !important;
}
button.centralbutton {
  font-weight: 500;
  color: #716e6e;
  background: #e8e8e8;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 80px;
  border: 1px solid #efeded;
  font-size: 18px;
  color: #373737 !important;
}
button.centralbutton:hover {
  background: #716e6e !important;
  color: #ffff !important;
  opacity: 0.6;
  transition: 0.3s;
  
}
.centraldata.fpage p{
  font-size: 18px;
  font-weight: 400;
  color: #4b4c4c !important;
}

.imagebox {
  padding-top: 190px;
  padding-bottom: 80px;
}
.bg.loginpage {
  width: 100%;
  padding: 0px;
  margin: 0px;
  background: #004a7d;
}
img.logo.fpage {
 margin-top: 20px; 
  width: 318px;
}
.col-md-3 img {
  border: 1px solid #e5e5e5b5;
  width: 100%;
}
.imagebox {
  display: flex;
  align-content: center;
  justify-content: center;
}
.col-md-3.fpage {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}
h2.pro {
  color: #4b4c4c !important;
  font-size: 24px !important;
  margin-bottom: 0px;
  background: #e8e8e8;
  padding: 12px 0px;
  font-weight: 400 !important;
}
p.pimage.fpage {
  padding-left: 12px !important;
  padding-right: 12px !important;
  color: #4b4c4c !important;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
}

a.fpagenav.nav-link::before {
  position: absolute;
  top:8px;
    content: '';
    height: 55%;
    border-right:1px solid #4b4c4c !important;
    left: 0px;
    z-index: 111;
}

span.logout a {
  text-decoration: none;
  color: #2e2e2e;
}

span.shop a {
  text-decoration: none;
  color: #2e2e2e;
}
a.fpagenav.first.nav-link::before{
  display: none;
}
span.pointsav {
  color: #4b4c4c !important;
}
a.catalog.nav-link {
  padding-right: 32px !important;
}
label.remember {
  color: #394c5a;
  font-size: 13px;
}
a.btn.btn-link.forgot {
  font-size: 13px;
}
.container-fluid.poh {
 
  font-size: 18px;
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
}
.container-fluid.poh h1{
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  line-height: 1.2;
  font-size: 36px !important;
}
.pointsdetails {
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 813px;
  margin: 0 auto;
  width: 100%;
}
/* span.threemonth::after {
  content: '';
  position: absolute;
  border-right: 1px solid green;
  width: 10px;
  height: 20px;
} */
/* span.sixmonth {
  padding: 7px 14px;
} */
/* span.tewlvemonth {
  padding: 7px 14px;
} */
thead.poh {
  background: #8d8d8d;
  font-size: 18px;
  font-weight: bold !important;
}
tr.tablerowcolor.poh {
  background: #e8e8e8;
}
p.tableline.poh {
  text-align: right;
}
td {
  font-size: 18px !important;
}
td:nth-child(2){
  color: #ff0000;
  vertical-align: middle;
}
td:nth-child(3){

  vertical-align: middle;
}
td:nth-child(1){

  vertical-align: middle;
}
/* td.refund.poh {
  color: #08a100;
} */
.col-md-9.poh {
  text-align: right;
  padding-right: 20px  !important;
}
span.threemonth.poh {
  color: #716e6e;
  position: relative;
  padding-right: 15px;
  padding-left: 10px;
}
span.sixmonth.poh {
  color: #716e6e;
  position: relative;
  padding-right: 15px;
  padding-left: 10px;
}
span.tewlvemonth.poh {
  color: #716e6e;
  position: relative;
  padding-left: 10px;
  padding-right: 15px;
}
.col-md-6.poh {
  padding: 0px 0px 5px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

span.threemonth.poh::after {
  content: '';
  position: absolute;
  border-right: 1.5px solid #8d8d8d;
  width: 5px;
  top: 3px;
  height: 19px;
  right: 4px;
}
span.sixmonth.poh::after {
  content: '';
  position: absolute;
  border-right: 1.5px solid #8d8d8d;
  width: 5px;
  top: 3px;
  height: 19px;
  right: 4px;
}
span.tewlvemonth.poh::after{
  content: '';
  position: absolute;
  border-right: 1.5px solid #8d8d8d;
  width: 5px;
  top: 3px;
  height: 19px;
  right: 4px;
}
.col-md-9.poh {
  padding: 0px;
  margin: 0px;
}
th:first-child {
  width: 50%;
}
th:nth-child(2) {
  width: 30%;
}
.col-md-1.poh svg{
  color: #6b6868;
  width: 22px;
}
h2.question {
  cursor: default;
}
.email:hover {
  cursor: pointer !important;
  font-size: 16px;
  color: #004a7e;
  text-decoration: underline;
}
span.phone:hover{
  cursor: pointer !important;
  font-size: 16px;
  color: #004a7e;
  text-decoration: underline;

}
small.address p:hover {
  cursor: default;
}
[role=button] {
  cursor: default;
}
a.catalog.nav-link:hover {
  color: #004a7d !important;
  text-decoration: underline !important;
}
:focus-visible {
  outline: none;

}
::placeholder {
  font-size: 14px;
  color: #141515;
}
.table>:not(:first-child) {
  border-top: 0px solid currentColor;
}
th {
  padding: 20px !important;
}
td {
  padding-left: 20px !important;
}
p.tableline.poh {
  font-size: 12px;
}


/* .rightside {
  float: right;
}
.upperheader {
  display: flex;
  
  height: 30px;
  background: #ebf1f3;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}
.container-fluid.up {

  margin: 0px;
  padding: 0px;
}
.col-md-5.fpage {
  padding-top: 20px;
}
.ant-picker-panels {

  padding-right: 20px;
}
span.phonee.pd {
  padding-right: 15px;
}
span.icons.pd svg {
  height: 18px;
  width: 18px;
}
span.phonee.pd svg{
  height: 18px;
  width: 18px;
}
.lowerheader {
  height: 70px;
  background: #646b6c;
} */
svg.print.poh {
  height: 25px;
  width: 25px;
}
svg.doc.poh {
  height: 22px;
  width: 22px;
}
p.tableline {
  font-size: 12px;
}
.ant-picker-panels 
{
  padding-right: 30px;
}
 svg {
  vertical-align: initial;
}
.col-md-8.date.poh {
  padding: 0px;
}
.col-md-8.date.poh {
  padding: 0px;
  padding-bottom: 5px;
}
.ant-picker-range-separator {
  padding: 0 23px !important;
}
/* span.shop:hover {
  text-decoration: underline;
}
span.logout:hover {
  text-decoration: underline;
} */
span.pointrefund {
  color: green;
}
td.pointrefund {
  color: green;
}
svg.doc.poh {
  vertical-align: bottom;
}
svg.print.poh {
  vertical-align: bottom;
}
.col-md-9.poh {
  padding-top: 4px;
}
svg.print.poh {
  margin-top: 6px;
}






span.tewlvemonth.poh a {
  color: #716e6e;
}
span.tewlvemonth.poh:hover {
  text-decoration: underline;
  cursor: pointer;
}
span.sixmonth.poh a {
  color: #716e6e;
}
span.sixmonth.poh:hover {
  text-decoration: underline;
  cursor: pointer;
}
span.threemonth.poh a {
  color: #716e6e;
}
span.threemonth.poh:hover{
  text-decoration: underline;
  cursor: pointer;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #8d8d8d;
}

.ant-picker-input > input:hover {
  border-color: #8d8d8d;
  border-right-width: 1px !important;
}
.central.contentlogin{
  display: flex;
    align-items: flex-end;
    width: 100%;
}


form.loginform{
  width:100%;
    margin-left: auto;
}




@media (min-width: 1400px){

  form.loginform {
    width: 77%;
    margin-right: auto;
    margin-left: -35px;

}
img.googleplay {
  /* width: 10%; */
  width: 208px;
  padding: 5px;
}


}

@media (min-width: 900px){

  .boxes {
    padding-top: 30px;
    padding-bottom: 30px;
}

}

